import * as React from "react";
import { Link } from "gatsby";

import Logo from '../components/Logo'
import instagram from "../img/social/instagram.svg";

const Footer = () => {
  
    return (
      <footer className="global-footer">
        <Logo />
        <section className="menu">
          <ul className="menu-list">
            <li>
              <Link to="/" className="navbar-item">
                Home
              </Link>
            </li>
            <li>
              <Link className="navbar-item" to="/about">
                About
              </Link>
            </li>
            <li>
              <Link className="navbar-item" to="/products">
                Products
              </Link>
            </li>
            <li>
              <Link className="navbar-item" to="/care-instructions">
                Care Instructions
              </Link>
            </li>
          </ul>
        </section>
        <div className="social">
          <a title="instagram" href="https://www.instagram.com/cloverleafyarn">
            <img
              src={instagram}
              alt="Instagram"
            />
          </a>
        </div>
      </footer>
    );
};

export default Footer;
